import React, { useState, useEffect } from 'react';

function Table(props) {
  const { data } = props;
  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {data.map((object) => (
          <tr key={object.id}>
            <td>{object.id}</td>
            <td>{object.name}</td>
            <td>{object.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function MyComponent() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('http://127.0.0.1:5000/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  return (
    <div>
      {data.map(item => (
        <div key={item.id}>
          <p>{item.name}</p>
          <p>{item.email}</p>
        </div>
      ))}
      <Table data={data} />
    </div>
  );
}

export default MyComponent;
